@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap');


body {
  box-sizing :border-box;
  font-family: "Fira Code", monospace;
  overflow-x: hidden;
  background-color: #f5f5f5;
}

header {
  padding: 1.06rem 1rem;
  background-color: #fff;
  height: 5vh;
  margin-top: -0.63rem;
  margin-right: -0.63rem;
  margin-left: -0.94rem;
  margin-bottom: 1.25rem;
}

header .logo {
  max-width: 100%;
  transform: translate(-13.75rem, -3.94rem);
  height: 29vh;
}

.logo-title {
  float: left;
  font-weight:900;
  transform: translate(9.38rem, 0.19rem);
  max-width: 100%;
}

header a {
  text-decoration: none;
  color: black
}

.menu {
  float:right;
}

.menu a {
  color: black;
  text-decoration:none;
  display:inline-block;
  padding: 0.63rem 1.25rem;
  border-radius: 0.19rem;
  transition: all 0.3s ease-in-out;
  cursor:pointer;
  font-weight: bold;
}

.menu a:hover {
  background: #45a049;
  color: #fff;
  cursor:pointer;
}


.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.controls-container {
  width: 80%;
  max-width: 43.75rem;
  background-color: #fff;
  padding: 1.25rem;
  border-radius: 0.63rem;
  box-shadow: -0.06rem 0px 0.63rem rgba(0, 0, 0, 1.1);
}

h1, h2, h3 {
  text-align: center;
}

#circular-title {
  font-size: 1rem;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.63rem;
  margin-bottom: 1.25rem;
  text-align: center;
}

.controls-title {
  margin: auto;
  color:#45a049;
  font-weight: 900;
  margin-bottom: 0.31rem;
  margin-top: 0.63rem;

}

.controls label {
  flex: 1 1 100%;
  margin-left: 1.88rem;
}

.controls input {
  flex: 1 1 calc(50% - 0.63rem);
}

input[type=number] {
    background: #0000;
    border: 1px solid #000;
    color: #000;
    font-size: 1rem;
    font-weight: 100;
    padding: 0.06rem 0px 0px 3.13rem;
    width: 10%;
}

.animation-controls {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.63rem;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.animation-controls.visible {
  max-height: 6.25rem;
  opacity: 1;
}

button {
  flex: 1 1 100%;
  padding: 0.63rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 0.31rem;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.preview, .code {
  margin-top: 1.25rem;
}

#progressContainer {
  width: 100%;
  background-color: #cccccc;
  border-radius: 0.63rem;
  overflow: hidden;
  position: relative;
}

#progressBar {
  width: 50%;
  height: 1.25rem;
  background-color: #4caf50;
}

.percentage {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: white;
  padding-right: 0.31rem;
}

#circularContainer {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 1;
  background-color: #cccccc;
  border-radius: 50%;
  overflow: hidden;
}
#circularSVG {
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
}

#circularBackground {
  fill: none;
  stroke: #cccccc;
  stroke-width: 20;
}

#circularBar {
  fill: none;
  stroke: #4caf50;
  stroke-width: 20;
}

.circularPercentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  color: #4caf50;
}


.code {
  margin-top: 1.25rem;
  overflow-x: auto;
}

.code pre {
  white-space: pre-wrap; /* Wrap long lines */
  word-wrap: break-word; /* Wrap long words */
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 52vh;
    text-align: center;
    margin: 1rem;
    line-height: 2rem;
}

.footer-container {
  margin-top: 10rem;
  margin-bottom: -5rem;
  background: #fff;
}

.policy-container {
  display:flex;
	justify-content: center;
  height: 8vh;
  border-top: 0.13rem solid #f4f7f9;
}

#footer-1 {
  margin-top: 1.63rem;
}

#footer-1 a {
  color: #45a049;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
 
}

#footer-1 a:hover {
 text-decoration: none;
}

#footer-2 {
  display:flex;
	justify-content: center;
  color:#000;
  padding-bottom: 1rem;
 
}

  @media (min-width:1550px) and (max-width: 3200px) {
  .logo-title{
    transform: translate(17.19rem, 1.25rem);
  }

  header .logo {
    transform: translate(-16.25rem, -7.94rem);
  }
}

@media screen and (max-width: 1500px)  {

  .logo-title {
    float: left;
    transform: translate(11.25rem, 0.63rem);

  } 

  header .logo {
    transform: translate(-16.25rem, -5.31rem);
    height: 30vh;
}
}

@media screen and (max-width:1498px) {
    .container {
      display: block;
    }

    header .logo {
      height: 6.63rem;
      width: 6.63rem;
      margin-top: -1.06rem;
      float: left;
      transform: none;
    }

    .logo-title {
      transform: translate(0.06rem, 0.88rem);
    }  

    .controls-container {
      margin: auto;
    }

}

@media screen and (max-width: 768px)  { 

  .menu a 
  {
    display: none;
  }

  .section {
    display: block;
  }

  .policy-container {
    height: 10vh;
    font-size: 0.63rem;
}

#footer-2 {
  font-size: 0.56rem;
}

}